@import url('https://fonts.googleapis.com/css2?family=Moderustic:wght@400;800&display=swap');

.footer {
  padding: 40px 20px;
  font-family: 'Moderustic', sans-serif;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-logo {
  flex: 1;
  text-align: center;
}

.footer-logo img {
  width: 200px;
  border-radius: 0%;
  margin-bottom: 10px;
}

.footer-logo p {
  font-size: 16px;
  color: #ffffff;
}

.footer-social {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.footer-social img {
  width: 30px;
  height: 30px;
}

.footer-links {
  display: flex;
  flex: 3;
  justify-content: space-around;
}

.footer-column {
  text-align: left;
}

.footer-column h4 {
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 10px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li a {
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
}

.footer-column ul li a:hover {
  color: #007BFF;
}

.footer-subscribe {
  display: flex;
  align-items: center;
  background-color: #f3f3f3;
  border-radius: 5px;
  padding: 5px;
}

.footer-subscribe input {
  border: none;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px 0 0 5px;
  flex: 1;
}

.footer-subscribe button {
  border: none;
  background-color: transparent;
  padding: 0 10px;
}

.footer-subscribe button img {
  width: 20px;
  height: 20px;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-links {
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }

  .footer-column {
    text-align: center;
    margin-bottom: 20px;
  }
}

/* Navbar.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px; /* Adjusted padding for alignment */
  color: #fff;
  font-family: 'Lexend', sans-serif;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 1200px; /* Set a max-width to align with other elements */
  margin: 0 auto; /* Center the navbar */
}

.navbar-logo a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
}

.navbar-logo img {
  height: 40px;
  width: auto;
  margin-right: 10px;
}

.navbar-links {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
}

.navbar-links a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  transition: color 0.3s;
}

.navbar-links a:hover {
  color: #ddd;
}

.navbar-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.toggle-bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
  transition: all 0.3s ease;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  color: #fff;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  display: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar {
    padding: 10px 20px;
  }

  .navbar-links {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background-color: #0B0B0B;
    padding-top: 60px;
    transition: right 0.3s ease-in-out;
    z-index: 1000;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .navbar-links.open {
    right: 0;
  }

  .navbar-links a {
    padding: 15px;
    font-size: 20px;
  }

  .navbar-toggle {
    display: flex;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .close-btn {
    display: block;
  }
}

/* Desktop Styles */
@media (min-width: 1200px) {
  .navbar {
    padding: 10px 0px; /* Adjusted to better align with the header */
    max-width: 1200px; /* Ensure it aligns with other content */
    margin: 0 auto; /* Center the navbar within the container */
  }

  .navbar-logo {
    margin-right: auto;
  }

  .navbar-links {
    gap: 40px;
    justify-content: flex-end;
  }

  .navbar-links a {
    font-size: 18px;
  }

  .navbar-toggle {
    display: none;
  }

  .navbar {
    display: flex;
    align-items: center;
  }
}
